<template>
  <!-- 顶部 -->
  <div class="header">
    <div
      class="header-content"
      v-if="
        aktivie_index == '/login' ||
        aktivie_index == '/staffcourse' ||
        aktivie_index == '/enterpriseinformation'
      "
    >
      <img
        @click="logoBtn"
        class="header-img"
        src="../../assets/img/header-login.png"
        alt=""
      />
      <img
        @click="logoBtn"
        class="header-img1"
        src="../../assets/img/logo1.png"
        alt=""
      />
      <nav class="header-content-title" v-if="aktivie_index !== '/login'">
        <router-link
          :class="{ aktivie: aktivie_index == item.path }"
          class="header-content-title-item item"
          :to="item.path"
          v-for="(item, index) in enterprise_list"
          :key="index"
          >{{ item.title }}</router-link
        >
      </nav>
      <el-dropdown
        v-if="
          aktivie_index == '/staffcourse' ||
          aktivie_index == '/enterpriseinformation'
        "
        class="header-content-icon c-row"
      >
        <img class="avatar-img" :src="circleUrl_change" alt="用户" />
        <!-- <el-avatar  :src="circleUrl" @error="errorHandler"></el-avatar> -->
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item disabled>{{ name || "" }}</el-dropdown-item>

          <el-dropdown-item @click.native="loginOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="header-content" v-else>
      <img class="image" :src="logo" @click="goHome" alt="logo" />
      <nav class="header-content-title">
        <div
          @click="goItem(item.path)"
          @mousemove="hoverBtn(index)"
          :class="{ aktivie: aktivie_index == item.path }"
          class="header-content-title-item"
          v-for="(item, index) in nav_list"
          :key="index"
        >
          {{ item.title }}
        </div>
      </nav>
      <div class="header-content-right" v-if="!login_showw">
        <span class="header-content-right-login" @click="loginBtn">登录</span>
        <span class="header-content-right-registr" @click="regBtn">注册</span>
      </div>

      <el-dropdown v-else class="header-content-icon c-row">
        <img class="avatar-img" :src="circleUrl_change" alt="用户" />
        <!-- <el-avatar  :src="circleUrl" @error="errorHandler"></el-avatar> -->
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item disabled>{{ name || "" }}</el-dropdown-item>
          <el-dropdown-item @click.native="$router.push('/personalCenter')"
            >个人中心</el-dropdown-item
          >
          <el-dropdown-item @click.native="loginOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div v-if="login_register">
        <Login
          :imgsrc="imgsrc"
          @login="login"
          :login__add_register="login__add_register"
          @go="go"
          @close="close"
          @getAvatar="getAvatar"
          @refresh="refresh"
        ></Login>
      </div>
    </div>

    <!-- 退出登录弹框 -->
  </div>
</template>

<script>
import local from "@/utils/local";
import Login from "@/components/login/index.vue";
import { loginout, get_login_img } from "@/api/index";
import { createLogger } from "vuex";
export default {
  components: {
    Login,
  },
  props: {
    login_show: {},
  },
  data() {
    return {
      pathurl: "",
      imgsrc: "",

      circleUrl: "",

      logo: require("../../assets/img/header-login.png"),
      aktivie_index: "", //激活索引
      hover_index: "",
      ob_login: false, //判断用户是否登录
      // login_register:this.$store.state.bool, //登录和注册弹框
      login__add_register: 1, //判断点击登录还是注册1登录2注册
      path: "", //当前页得路由地址
      nav_list: [
        {
          name: 0,
          title: "首页",
          path: "/index",
        },
        {
          name: 1,
          title: "全部课程",
          path: "/allLehrplan",
        },
        {
          name: 2,
          title: "实时课堂",
          path: "/allLiveStreaming",
        },
        // {
        //   name: 3,
        //   title: "合作院校",
        //   path: "/partnerSchools",
        // },
        {
          name: 4,
          title: "在线题库",
          path: "/mockexam",
        },
        {
          name: 5,
          title: "关于我们",
          path: "/aboutUs",
        },
        {
          name: 6,
          title: "企业服务",
          path: "/enterpriseCulture",
        },
      ], //导航头
      enterprise_list: [
        {
          name: 0,
          title: "员工课程",
          path: "/staffcourse",
        },
        {
          name: 1,
          title: "企业资料",
          path: "/enterpriseinformation",
        },
      ],
    };
  },
  created() {
    // this.getcodeImg();
    if (local.get("xjztoken")) {
      this.ob_login = true;
    }

    if (local.get("username")) {
      this.circleUrl = local.get("username").avatar;
    }
  },
  mounted() {
    console.log(888999, this.login_show);
  },

  computed: {
    circleUrl_change() {
      return this.$store.state.circleUrl_change;
    },
    login_register() {
      return this.$store.state.bool;
    },
    login_showw() {
      return this.$store.state.login_show;
    },
    name() {
      return this.$store.state.name;
    },
  },
  methods: {
    // 移入事件
    hoverBtn(val) {
      console.log("val :>> ", val);
      this.hover_index = val;
    },
    logoBtn() {
      if (this.$route.path == "/login") {
        this.$router.push("/");
      }
    },
    refresh() {
      // this.getcodeImg();
    },
    //点击登录
    loginBtn() {
      this.$router.push("/login");
      this.$store.commit("CHANGE_STATUS", 1);
    },
    //点击注册
    regBtn() {
      this.$router.push("/login");
      this.$store.commit("CHANGE_STATUS", 2);
    },

    // 获取滑块背景图
    // getcodeImg() {
    //   get_login_img().then((res) => {
    //     console.log(88, res);
    //     this.imgsrc = res.data.data.image_url;
    //   });
    // },
    goHome() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    },
    //点击登录获取头像
    getAvatar(val) {
      this.circleUrl = val;
    },
    errorHandler() {
      console.log(111);
      // console.log("图片加载失败");
    },
    goItem(val, index) {
      this.$router.push(val);
    },
    //点击登录按钮
    login(val) {
      this.ob_login = !val;
      this.$store.commit("CHANGE", val);
      this.$emit("loginOk", null);
    },
    //点击关闭图标
    close(val) {
      this.$store.commit("CHANGE", val);
      this.$store.commit("LOSE", false);
      console.log(8989, this.$route.path);
      if (this.$route.path !== "/" || this.$route.path !== "/index") {
        this.$router.push("/index");
      }
    },
    go(val) {
      this.login__add_register = val;
    },
    //退出登录
    loginOut() {
      this.$confirm("是否确定退出登录 ", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          loginout().then((res) => {
            if (res.data.code == 1) {
              this.$store.commit("LOGIN", false);
              local.clear();
              this.ob_login = false;

              if (this.$route.path !== "/") this.$router.push("/");
            }
          });
        })
        .catch(() => {});
    },
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.aktivie_index = val.path;
      },
      // 深度观察监听
      deep: true,
    },
    login_show: {
      handler: function (val, oldVal) {
        console.log("新旧", val, oldVal);
        if (val == false) {
          this.ob_login = val;
        }
      },
    },
  },
};
</script>

<style  scoped lang="scss">
a {
  text-decoration: none;
  color: #000;
}
.header {
  height: 90px;
  box-shadow: 0px 4px 9px 0px rgba(59, 59, 59, 0.16);
  .header-content {
    position: relative;
    width: 1200px;
    height: 90px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .image {
      height: 53px;
      width: 63px;
    }
    .header-content-title {
      margin-left: 40px;
      display: flex;
      height: 90px;
      .header-content-title-item {
        margin-top: 25px;
        margin-right: 32px;
        padding: 10px 0;
        height: 40px;
        cursor: pointer;
        font-size: 16px;
      }
      .header-content-title-item:hover {
        color: #1769fe;

;
        margin-top: 20px;
      }
    }
    .header-content-right {
      cursor: pointer;
      position: absolute;
      right: 0;
      padding: 9px 0px;
      width: 135px;
      height: 40px;
      background: #004ab1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      border-radius: 5px;
      .header-content-right-login,
      .header-content-right-registr {
        height: 40px;
        line-height: 40px;
        text-align: center;
        width: 50%;
      }
    }
    .header-content-right:hover{
background:#2b6cc6 !important;
    }
    .header-content-right:after {
      content: "";
      height: 21px;
      width: 1px;
      background: #fff;
      position: absolute;
      left: 67px;
    }
    .header-content-icon {
      position: absolute;
      right: 0;
      height: 50px;
      width: 50px;
      color: #1769fe;
      font-size: 38px;
      background: #eaf4fe;
      border-radius: 50%;
      display: flex;
      .avatar-img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
      }
    }
  }
}
.aktivie {
  border-bottom: 2px solid #1769fe;
  color: #1769fe;
}
.header-img {
  height: 42px;
  width: 50px;
}
.header-img1 {
  height: 34px;
  width: 96px;
}
</style>

