<template>
  <div
    :class="{
      layout:
        $route.path !== '/allLiveStreaming/details' ||
        $route.path !== '/myAutonymDetail',
    }"
  >
    <template>
      <div
        class="header"
        v-if="
          $route.path !== '/allLiveStreaming/details' ||
          $route.path !== '/myAutonymDetail'
        "
      >
        <Head :password_num="password_num"  @loginOk="loginOk" :login_show="login_show" />
      </div>
      <div class="hmain">
        <div class="main-box">
          <router-view></router-view>
        </div>
        <div class="footer">
          <Footer />
          
        </div>
      </div>
      <div class="Gesamtlage" v-if="false">
        <div class="Gesamtlage-content"></div>
      </div>
    </template>
    <template v-if="false">
      <div>
        <router-view></router-view>
      </div>
    </template>
    
    <div class="service" v-if="service_show">
      <div class="phone" @click="phoneBtn">联系我们</div>
      <div class="help" @click="banZhu">使用帮助</div>
    </div>
  </div>
</template>

<script>
import local from "./utils/local";
import Footer from "./components/footer/index.vue";
import Head from "./components/head/index.vue";
import { MessageBox, Message } from "element-ui";

export default {
  components: {
    Footer,
    Head,
  },
  data() {
    return {
      debut: false,
      debut: [], //开播直播间数组
      id: "", //直播间id
      websock: null,
      token: local.get("xjztoken") ? local.get("xjztoken") : "",
      username: local.get("username") ? local.get("username") : {},
      login_show: null,
      service_show: true,
      expiretime: null,
      time: null,//定时器
      password_num:0,
      isReady: true
    };
  },
  created() {
    
  
    // 进入页面就判断用户是否登录
    if (this.token) {
      console.log('进入页面就判断用户是否登录',this.token);
      this.$store.commit("LOGIN", true);
      let type=local.get("type")
      if(type==2){
        this.$router.push("/staffcourse")
      }
    }
  },

  mounted() {
    // 禁止打开控制
    document.onkeydown = () => {
      //禁用F12
      if (window.event && window.event.keyCode == 123) {
        return false;
        //禁用ctrl+shift+i,
      } else if (
        window.event.ctrlKey &&
        window.event.shiftKey &&
        window.event.keyCode == 73
      ) {
        return false;
        //屏蔽Shift+F10
      } else if (window.event.shiftKey && window.event.keyCode == 121) {
        return false;
      }
    };

    // 禁止鼠标右键菜单
    document.oncontextmenu = function (e) {
      return false;
    };

    this.$ws.initWebpack();
    window.addEventListener("onmessageWS", this.getMsg());
    if (this.isMobile()) {
      // 检测是移动端跳转h5网址
      // window.location.href = "https://h5.17vt.cn";
    }
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    loginOk(val) {
      this.login_show = null;
    },

    getMsg() {
      this.$store.state.ws.onmessage = (val) => {
        let msg = JSON.parse(val.data);
console.log(msg);
        if (msg.msg_type == "live_open_msg") {
          this.debut = msg.data.live_open;
          this.id = msg.data.room_id;
        } else if (msg.msg_type == "ping") {
          this.$store.state.ws.send("嘣");
        } else if (msg.msg_type == "other_login") {
          setTimeout(() => {
            let user = local.get("username");
            console.log("user", user);
            if (
              user?.user_id == msg.data.user_id &&
              msg.data.token !== user.token
            ) {
              if(this.$route.path!=="/lehrolanStudie"){
                this.login_show = false;
              localStorage.clear();
             
              this.$utils.bulletBox("你的账号已在其它设备登录", "/index");
              } 
            }
          }, 500);
        }else if(msg.msg_type == "password_exp"){
          //密码到期提示语
           let user = local.get("username");
          if(user?.user_id==msg.data.user_id&&this.isReady){
            this.isReady=false
            this.password_data=msg
           this.$utils.prompt('error',msg.text)
          }
        }
      };
    },
    // 跳转反馈页面
    phoneBtn() {
      window.open("https://txc.qq.com/products/612556", "_blank");
    },
    banZhu() {
      this.$router.push("/help");
    },
    mousewheelHandler(e) {
      e.preventDefault();
    },
  },
  computed: {
    login_register() {
      return this.$store.state.bool;
    },
    lose_efficacy() {
      return this.$store.state.lose_efficacy;
    },
    // 当前剩余失效时间
    loseefficacy() {
     
      let num = local.get("username")?.expiretime * 1000 - this.expiretime;

      return num;
    },
 
  // 是否是登录状态
    login_bool() {
      return this.$store.state.login_show;
    },
  },
  watch: {
  // 监听登录状态
    login_bool(val) {
      console.log("是否已登录", val);
      if (val) {
        this.time = setInterval(() => {
          this.expiretime = new Date().getTime();
         
        }, 1000);
      } else {
        clearInterval(this.time);
      }
    },
    // 监听当前剩余失效时间
    loseefficacy(val, old) {
    
      
      if (val <0) {
        clearInterval(this.time);
        if(this.$route.path=="/lehrolanStudie"){
          this.$router.back();
        }
        local.clear()
        
        setTimeout(() => {
          this.$store.commit("LOSE", true);
        }, 500);
      }
    },
    // 监听登录框打开静止页面滚动
    login_register(val, old) {
      if (val) {
        this.$nextTick(() => {
          document.addEventListener("mousewheel", this.mousewheelHandler, {
            passive: false,
          });
        });
      } else {
        this.$nextTick(() => {
          document.removeEventListener("mousewheel", this.mousewheelHandler, {
            passive: false,
          });
        });
      }
    },
    lose_efficacy(val, old) {
      
      if (val) {

        setTimeout(() => {
            this.$store.commit("CHANGE", true);
            this.$store.commit('CHANGEPAW', 1)
            this.$store.commit("LOGIN", false);
          }, 500);
        MessageBox.confirm("您还未登录或者登录已失效请登录", "提示", {
          confirmButtonText: "确定",
          showCancelButton: false,
          closeOnClickModal: false,
          showClose: false,
          type: "warning",
          center: true,
        }).then(() => {
          this.$router.push("/index")
        }).catch(()=>{
          this.$router.push("/index")
        })
      } else {
      }
    },
    debut(val) {
      if (this.token !== "" && this.id.toString() !== this.$route.query.id) {
        if (val) {
          this.$confirm("是否进入直播间", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.debut = false;

              let path = this.$router.resolve(
                "/allLiveStreaming/details?id=" + this.id
              );
              window.open(path.href, "_blank");
            })
            .catch(() => {
              this.debut = false;
            });
        }
      }
    },

    $route: {
      handler: function (val, oldVal) {
        console.log(8989,val);
        
        if (
          val.path == "/lehrolanStudie" ||
          val.path == "/allLiveStreaming/details" ||
          val.path == "/fragenbank"||
          val.path == "/staffcourse"||
          val.path == "/enterpriseinformation"||
          val.path == "/login"
        ) {
          this.service_show = false;
        } else {
          this.service_show = true;
        }
        
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>


.layout {
  height: 100%;
  padding-top: 90px;
  display: flex;
}
.header {
  background: #fff;
  width: 100%;
  height: 90px;
  position: fixed;
  top: 0;
  left: 0;

  z-index: 99;
}

.hmain {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  .main-box {
    flex: 1;
    background-color: #f5f6f6;
  }

  .footer {
    height: 150px;
    width: 100%;
  }
  .Gesamtlage {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
   
  }
}
.service {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 999999999;
  height: 140px;
  width: 50px;

  background: #fff;
  position: fixed;
  top: 50%;
  transform: translate(-50%);
  right: -20px;
  border-radius: 7px;
  overflow: hidden;
  .phone {
    height: 70px;
    background: #1769fe;
    font-size: 16px;
    padding: 8px;
    color: #fff;
  }
  .help {
    font-size: 16px;
    padding: 8px;

    height: 70px;
  }
}
</style>
<style lang="scss">
/* 垂直滚动条样式 */
/* 宽度 */
::-webkit-scrollbar {
    width: 5px;
}
/* 背景色 */
::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}
/* 滑块颜色 */
::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
}

/* 水平滚动条样式 */
/* 高度 */
::-webkit-scrollbar {
    height:0;
}
/* 背景色 */
::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}
/* 滑块颜色 */
::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
}
</style>