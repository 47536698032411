export const yzqRouter = [
    {
        path: "/allLehrplan", component: () => import("../views/allLehrplan/index.vue"),
        meta: {
            alive: true,
            title:"全部课程"
        }
    },
    {
        path: "/fragenbank", component: () => import("../views/fragenbank/index.vue"),
        meta: {
            alive: true
        }
    },
    {
        path: "/mockexam", component: () => import("../views/mockExam/index.vue"),
        meta: {
            alive: true,
            title:"在线题库",
            keywords:'xxx',//内容
            description:'xxx'//描述
        }
    },
   



]