<template>
  <div class="footer">
    <div class="footer-item">
      <span
        v-for="(item, index) in list"
        :key="index"
        class="footer-item-text activehover"
        @click="gourl(item)"
        >{{ item.name }} <span v-if="index !== list.length - 1">|</span></span
      >
      <div class="copyright"> <span class="activehover">Copyright © 2023 重庆学教长科技有限公司</span>  <span>|</span> <span  class="activehover"  @click="goBtn">渝ICP备2023005570号</span><span style="margin: 0 5px;">|</span> <span></span> <img src="../../assets//img/public.png" alt=""> 渝公网安备：  <span class="activehover" @click="filings(1)" > 50010302005026号</span> <span style="margin: 0 5px;">|</span>  <img src="../../assets//img/public.png" alt=""><span class="activehover">  渝公网安备：  <span  @click="filings(2)" > 50010302005009号</span></span>   </div>
     
      <div class="copyright"  >
      <span class="activehover"> 增值电信经营许可证：渝B2-20230418</span>   <span>|</span> <span   class="activehover" @click="goPermit" >广播电视节目制作经营许可证：(渝) 01455号</span> 
      </div>
      <div class="foter_image">
        <div  class="app-weix activehover"><span> 扫码下载安卓版</span> <img src="../../assets/img/appimg.jpg" alt="扫码下载安卓版"></div>
        <div class="app-weix activehover"> <span>微信小程序</span>  <img src="../../assets/img/8e7a5acbd06e07b4a2de41847edac71.png" alt="微信小程序" /></div>
</div>
    </div>
    
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { name: "加入我们", url: "" },
        { name: "联系邮箱：xjz87215664@163.com，联系电话：13883979997", url: "" },
        { name: "关于我们", url: "" },
        { name: "服务协议", url: "/service" },
        {name:"隐私政策", url:"privacy"},
        { name: "帮助与反馈", url: "/help" },
        { name: "更新日志", url: "/updatelog" },
      ],
    };
  },
  methods: {
    gourl(val) {
      this.$router.push(val.url);
     
     
    },
    goBtn(){
        let path="https://beian.miit.gov.cn"
        window.open(path,'_blank')
    },
    goPermit(){
      let path="https://admin.xjzvt.com/uploads/20230809/83eca26e2cfa2200d2a717b9c29a3d0d.jpg"
        window.open(path,'_blank')
      
    },
    filings(val){
      if(val==1){
        let path="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010302005026"
        window.open(path,'_blank')
      }else{
        let path="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010302005009"
        window.open(path,'_blank')
      }
      
       
    }
    
  },
};
</script>

<style lang="scss" scoped>
.footer {
  height: 100%;
  background: #1f2833;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 

  .footer-item {
    width: 1200px;
    color: #7b8a95;
    font-size: 14px;
    position: relative;
    .copyright{
        margin-top: 20px;
        cursor:pointer;
        display: flex;
        align-items: center;
        img{
          height: 15px;
          width: 15px;
          margin-right: 5px;
        }
    }

    .footer-item-text {
      cursor: pointer;
      padding-left: 6px;
      
    }
  }
}
.foter_image{
  height: 130px;
  width: 230px;
  position: absolute;
  right: 0px;
  top:-20px;
  display: flex;
  justify-content: space-between;
  .app-weix{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    img{
      width: 100px;
      height: 100px;
    }
  }
}
.activehover:hover{
  color: #fff;
  border-bottom: 1px solid #fff;
}
</style>