import { MessageBox,Message  } from 'element-ui'
import router from "@/router/index"
export default {
    //退出全屏
    exitFullscreen() {
        if (window.document.exitFullscreen) {
            window.document.exitFullscreen();
        } else if (window.document.msExitFullscreen) {
            window.document.msExitFullscreen();
        } else if (window.document.mozCancelFullScreen) {
            window.document.mozCancelFullScreen();
        } else if (window.document.oRequestFullscreen) {
            window.document.oCancelFullScreen();
        } else if (window.document.webkitExitFullscreen) {
            window.document.webkitExitFullscreen();
        }
    },
    bulletBox(msg,path){
        MessageBox.confirm(msg, "提示", {
            confirmButtonText: "确定",
            showCancelButton: false,
            closeOnClickModal:false,
            showClose: false,
            type: "warning",
            center: true,
          }).then(() => {
           
            if(path){
                router.push(path)
            }else{
                router.back();

            }
            setTimeout(()=>{
                location.reload()//强制刷新页面
            },10)
          });
          
    },
    prompt(val,text){
        Message({
            message:text,
            type: val,
          });
    }
} 