import request from "@/utils/request.js"

// 示例  login
// 登录接口
export const login = data => {
    return request({
        url: 'user/login',
        method:'post',
        data
    })
}
//重置密码

export const resetpwd = data=>{
    return request({
        url:'user/resetpwd',
        method: 'post',
        data
    })
}
// 重置密码手机验证码发送
export const send = data=>{
    return request({
        url:'sms/send',
        method: 'post',
        data
    })
}

// 验证用户信息
export const check_user = data=>{
    return request({
        url:'user/check_user',
        method: 'post',
        data
    })
}
//退出登录
export const loginout = data=>{
    return request({
        url:'user/logout',
        method: 'post',
        data
    })
}

//注册
export const register = data => {
    return request({
        url: 'user/register',
        method: 'post',
        data
    })
}
//首页-轮播图
export const get_banner = data => {
    return request({
        url: 'index/banner',
        method: 'post',
        data
    })
}
//首页-合作院校
export const get_school_list = data => {
    return request({
        url:'index/school_list',
        method:'post',
        data
        
    })
}
//首页-热门课程
export const get_hot_subject = data => {
    return request({
        url:'index/get_hot_subject',
        method:'post',
        data
        
    })
}//首页热门直播
export const get_hot_live = data => {
    return request({
        url:'index/get_hot_live',
        method:'post',
        data
     
    })
}//首页-大分类
export const get_big_cate = data => {
    return request({
        url:'index/get_big_cate',
        method:'post',
        data
       
    })
}
//首页-其他大类课程
export const get_cate_subject = data => {
    return request({
        url: 'index/get_cate_subject',//请求地址
        method: 'post',//请求方式
        data//请求参数
    })
}
//个人中心 - 退出登录
export const logout = data => {
    return request({
        url: 'user/logout',//请求地址
        method: 'post',//请求方式
        data//请求参数
    })
}
//个人中心 - 我的课程列表
export const user_subject = data => {
    return request({
        url: 'user_subject/index',//请求地址
        method: 'post',//请求方式
        data//请求参数
    })
}
//个人中心 - 我的练习列表
export const my_practise = data => {
    return request({
        url: 'user_subject/my_practise',//请求地址
        method: 'post',//请求方式
        data//请求参数
    })
}
//个人中心 - 开始练习
export const subject_begin_questions = data => {
    return request({
        url: 'questions/subject_begin_questions',//请求地址
        method: 'post',//请求方式
        data//请求参数
    })
}
//个人中心 - 添加联系记录
export const add_practise = data => {
    return request({
        url: 'questions/add_practise',//请求地址
        method: 'post',//请求方式
        data//请求参数
    })
}
//个人中心 - 获取试卷-开始考试或重新考试
export const add_exam = data => {
    return request({
        url: 'questions/add_exam',//请求地址
        method: 'post',//请求方式
        data//请求参数
    })
}
//个人中心 - 获取试卷题目内容
export const get_exam_detail = data => {
    return request({
        url: 'questions/get_exam_detail',//请求地址
        method: 'post',//请求方式
        data//请求参数
    })
}
//个人中心 - 提交试卷的题目
export const sub_exam = data => {
    return request({
        url: 'questions/sub_exam',//请求地址
        method: 'post',//请求方式
        data//请求参数
    })
}
//个人中心 - 提交试卷
export const sub_my_exam = data => {
    return request({
        url: 'questions/sub_my_exam',//请求地址
        method: 'post',//请求方式
        data//请求参数
    })
}
//个人中心 - 我的考试-列表
export const my_exam = data => {
    return request({
        url: 'user_subject/my_exam',//请求地址
        method: 'post',//请求方式
        data//请求参数
    })
}
//个人中心 -试卷报告
export const get_exam_report = data => {
    return request({
        url: 'user_subject/get_exam_report',//请求地址
        method: 'post',//请求方式
        data//请求参数
    })
}
//个人中心 -我的证书-列表
export const get_my_cert = data => {
    return request({
        url: 'user_subject/get_my_cert',//请求地址
        method: 'post',//请求方式
        data//请求参数
    })
}
//个人中心 -提交实名认证
export const edit_auth = data => {
    return request({
        url: 'user_auth/edit_auth',//请求地址
        method: 'post',//请求方式
        data//请求参数
    })
}
//个人中心 -认证情况
export const user_auth = data => {
    return request({
        url:'user_auth/index',//请求地址
        method:'post',//请求方式
        data//请求参数
    })
}
//个人中心 -申请学时证明
export const add_study_file = data => {
    return request({
        url:'user_subject/add_study_file',//请求地址
        method:'post',//请求方式
        data//请求参数
    })
}
//全部课程
// 1课程列表
export const subject_list = data => {
    return request({
        url: 'subject/index',
        method: 'post',
        data
    })
}
//2课程详情
export const subject_mx = data => {
    return request({
        url: 'subject/subject_mx',
        method: 'post',
        data
    })
}
//获取章节内容、列表
export const get_subject_sections = data => {
    return request({
        url: 'subject/get_subject_sections',
        method: 'post',
        data
    })
}
//获取网路视频地址
export const get_video = data => {
    return request({
        url: 'subject/get_video',
        method: 'post',
        data
    })
}
//课程报名
export const apply = data => {
    return request({
        url: 'subject/subject_apply',
        method: 'post',
        data
    })
}
//全部直播

//全部直播列表
export const user_live = data => {
    return request({
        url: 'user_live/index',
        method: 'post',
        data
    })
}
//直播详情
export const live_mx = data => {
    return request({
        url: 'user_live/live_mx',
        method: 'post',
        data
    })
}
//直播章节
export const get_live_sections = data => {
    return request({
        url: 'user_live/get_live_sections',
        method: 'post',
        data
    })
}
//讲师介绍
export const get_teacher = data => {
    return request({
        url: 'user_live/get_teacher',
        method: 'post',
        data
    })
}

//其他公共接口

//上传
export const upload = data => {
    return request({
        url: 'common/upload',
        method: 'post',
        data
    })
}
// 重新练习
export const clear_questions = data => {
    return request({
        url: 'questions/clear_questions',
        method: 'post',
        data
    })
}
// 清空试卷
export const clear_exam = data => {
    return request({
        url: 'questions/clear_exam',
        method: 'post',
        data
    })
}
// /图片转换地址
export const get_img = data => {
    return request({
        url: 'subject/get_img',
        method: 'post',
        data
    })
}
// 上传学习证明照片
export const upload_study_image = data => {
    return request({
        url: 'subject/upload_study_image',
        method: 'post',
        data
    })
}
// 上传学习进度
export const add_user_study = data => {
    return request({
        url: 'subject/add_user_study',
        method: 'post',
        data
    })
}

// 随机弹框题目
export const question_pop = data => {
    return request({
        url: 'subject/question_pop',
        method: 'post',
        data
    })
}
//获取当前用户在直播间是否被禁言
export const get_prohi_mx = data => {
    return request({
        url: 'user_live/get_prohi_mx',
        method: 'post',
        data
    })
}

//获取当前用户在直播间是否被禁言
export const get_chat_list = data => {
    return request({
        url: 'index/get_chat_list',
        method: 'post',
        data
    })
}
//获取直播间成员
export const get_live_user = data => {
    return request({
        url: 'index/get_live_user',
        method: 'post',
        data
    })
}
//学员签到情况
export const get_live_sign = data => {
    return request({
        url: 'user_live/get_live_sign',
        method: 'post',
        data
    })
}
// 直播间签到
export const add_sign = data => {
    return request({
        url: 'user_live/add_sign',
        method: 'post',
        data
    })
}
//获取视频观看人数
export const get_look_video_num = data => {
    return request({
        url: 'index/get_look_video_num',
        method: 'post',
        data
    })
}
//上传直播抓拍照片
export const get_live_image = data => {
    return request({
        url: 'user_live/get_live_image',
        method: 'post',
        data
    })
}
// 获取模拟考试科目列表
export const exam_list = data => {
    return request({
        url: 'questions/get_subject_exam_list',
        method: 'post',
        data
    })
}
// 获取模拟考试科目列表
export const my_exam_list = data => {
    return request({
        url: 'user_subject/get_my_exam_list',
        method: 'post',
        data
    })
}
//提交直播答案
export const add_live_questions = data => {
    return request({
        url: 'user_live/add_live_questions',
        method: 'post',
        data
    })
}

export const get_cate_detail = data => {
    return request({
        url: 'index/get_cate_detail',
        method: 'post',
        data
    })
}
//
// 我的班级
export const get_my_group_list = data => {
    return request({
        url: 'user_subject/get_my_group_list',
        method: 'post',
        data
    })
}
// 获取视频校验动作以及sessionid
export const get_video_code = data => {
    return request({
        url: 'user_auth/get_video_code',
        method: 'post',
        data
    })
}
// 班级签到
export const group_sign = data => {
    return request({
        url: 'user_subject/group_sign',
        method: 'post',
        data
    })
}
// 签到记录
export const get_my_sign_list = data => {
    return request({
        url: 'user_subject/get_my_sign_list',
        method: 'post',
        data
    })
}
// 今日是否签到
export const get_today_sign = data => {
    return request({
        url: 'user_subject/get_today_sign',
        method: 'post',
        data
    })
}
// 支付
// 课程详情
export const creat_order = data => {
    return request({
        url: 'order/creat_order',
        method: 'post',
        data
    })
}
// 支付列表
export const pay_again = data => {
    return request({
        url: 'order/pay_again',
        method: 'post',
        data
    })
}
//订单列表
export const get_my_ex = data => {
    return request({
        url: 'user_subject/get_my_ex',
        method: 'post',
        data
    })
}
//订单列表
export const check_order = data => {
    return request({
        url: 'order/check_order',
        method: 'post',
        data
    })
}

//兑换订单
export const cansel_order = data => {
    return request({
        url: 'order/cansel_order',
        method: 'post',
        data
    })
}
//获取兑换规制
export const exchange_buy_word = data => {
    return request({
        url: 'publics/exchange_buy_word',
        method: 'post',
        data
    })
}
//订单评价
export const sub_evaluate = data => {
    return request({
        url: 'user_subject/sub_evaluate',
        method: 'post',
        data
    })
}
//申请退款
export const sub_refund = data => {
    return request({
        url: 'user_subject/sub_refund',
        method: 'post',
        data
    })
}
//申请发票
export const sub_invoice = data => {
    return request({
        url: 'user_subject/sub_invoice',
        method: 'post',
        data
    })
}
//课程评价
export const get_evaluate_list = data => {
    return request({
        url: 'subject/get_evaluate_list',
        method: 'post',
        data
    })
}
//课程评价
export const get_my_invoice_list = data => {
    return request({
        url: 'user_subject/get_my_invoice_list',
        method: 'post',
        data
    })
}
//获取登录滑块验证背景图
export const get_login_img = data => {
    return request({
        url: 'publics/get_login_img',
        method: 'post',
        data
    })
}
// 网站更新日志

export const web_version = data => {
    return request({
        url: 'publics/web_version',
        method: 'post',
        data
    })
}
// 帮助文档-手册
export const web_help = data => {
    return request({
        url: 'publics/web_help',
        method: 'post',
        data
    })
}
// 隐私政策
export const get_user_word = data => {
    return request({
        url: 'index/get_user_word',
        method: 'post',
        data
    })
}
//修改密码
export const changepwd = data => {
    return request({
        url: 'user/changepwd',
        method: 'post',
        data
    })
}
//修改头像身份证姓名
export const profile = data => {
    return request({
        url: 'user/profile',
        method: 'post',
        data
    })
}
//修改手机号
export const changemobile = data => {
    return request({
        url: 'user/changemobile',
        method: 'post',
        data
    })
}
//注册学校列表
export const select_school = data => {
    return request({
        url: 'index/select_school',
        method: 'post',
        data
    })
}
// 企业注册
export const company_register = data => {
    return request({
        url: 'company/register',
        method: 'post',
        data
    })
}

// 企业资料认证
export const add_info = data => {
    return request({
        url: 'company/add_info',
        method: 'post',
        data
    })
}
//企业登录
export const company_login = data => {
    return request({
        url: 'company/login',
        method: 'post',
        data
    })
}
// 获取学员列表
export const company_user_list = data => {
    return request({
        url: 'company/company_user_list',
        method: 'post',
        data
    })
}
// 企业认证详情
export const auth_index = data => {
    return request({
        url: 'company/auth_index',
        method: 'post',
        data
    })
}

//         在线题库
// 在线题库
export const get_subject_question_new_list = data => {
    return request({
        url: 'questions/get_subject_question_new_list',
        method: 'post',
        data
    })
}
// 我的题库
export const my_subject_questions_new_list = data => {
    return request({
        url: 'user_subject/my_subject_questions_new_list',
        method: 'post',
        data
    })
}
// 题库报名
export const apply_subject_questions = data => {
    return request({
        url: 'questions/apply_subject_questions',
        method: 'post',
        data
    })
}
// 题库购买
export const creat_questions_order = data => {
    return request({
        url: 'order/creat_questions_order',
        method: 'post',
        data
    })
}
// 题库-待支付订单-支付
export const pay_quest_again = data => {
    return request({
        url: 'order/pay_quest_again',
        method: 'post',
        data
    })
}
// 题库-练习分析
export const get_my_questions_analysis = data => {
    return request({
        url: 'user_subject/get_my_questions_analysis',
        method: 'post',
        data
    })
}



















