var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    layout:
      _vm.$route.path !== '/allLiveStreaming/details' ||
      _vm.$route.path !== '/myAutonymDetail',
  }},[[(
        _vm.$route.path !== '/allLiveStreaming/details' ||
        _vm.$route.path !== '/myAutonymDetail'
      )?_c('div',{staticClass:"header"},[_c('Head',{attrs:{"password_num":_vm.password_num,"login_show":_vm.login_show},on:{"loginOk":_vm.loginOk}})],1):_vm._e(),_c('div',{staticClass:"hmain"},[_c('div',{staticClass:"main-box"},[_c('router-view')],1),_c('div',{staticClass:"footer"},[_c('Footer')],1)]),(false)?_c('div',{staticClass:"Gesamtlage"},[_c('div',{staticClass:"Gesamtlage-content"})]):_vm._e()],(false)?[_c('div',[_c('router-view')],1)]:_vm._e(),(_vm.service_show)?_c('div',{staticClass:"service"},[_c('div',{staticClass:"phone",on:{"click":_vm.phoneBtn}},[_vm._v("联系我们")]),_c('div',{staticClass:"help",on:{"click":_vm.banZhu}},[_vm._v("使用帮助")])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }