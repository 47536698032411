import Vue from "vue";
import Vuex from "vuex";
import local from "@/utils/local"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    bool: false,
    lose_efficacy: false,
    permissions: false,
    url: "",
    //ws参数
    path: "",
    ws: null, //建立的连接
    lockReconnect: false, //是否真正建立连接
    timeout: 20 * 1000, //20秒一次心跳
    timeoutObj: null, //心跳心跳倒计时
    serverTimeoutObj: null, //心跳倒计时
    timeoutnum: null, //断开 重连倒计时
    msg: {}, //websocket接受到得消息
    login_show: false, //是否是登录状态
    pasw_show: 1,
    change_take_switch:true,
    circleUrl_change:local.get("username")?.avatar||'',
    name:local.get("username") ? local.get("username").nickname : "张三",
    status:1,//1登录或者2注册3忘记密码
    
  },
  getters: {
  
  },
  mutations: {
    CHANGEURL(state,val){
     
      state.circleUrl_change=val
    },
    CHANGESWITSH(state,val){
      state.change_take_switch=val
    },
    CHANGEPAW(state, val) {
      state.pasw_show = val;
    },
    LOGIN(state, val) {
      state.login_show = val;
    },
    CHANGE(state, val) {
      state.bool = val;
    },

    LOSE(state, val) {
      state.lose_efficacy = val;
    },
    SOCK_MSG(state, val) {
      state.msg = val;
      
    },


    CHANGE_STATUS(state,val){
      state.status=val
    },
    CHANGE_NAME(state,val){
      state.name=val
    }
  },
  actions: {},
  modules: {},
});
