// 节流函数

 const  debounce = function (fn, time) {
    // 等待的时间默认200ms 
    let delay = time;
    let timer = null;
    return function () {
        const _this = this;
        // 保存调用时传来的参数
        const args = arguments;
        // 每次事件被触发时，都清除之前的旧定时器
        if (timer) {
            clearTimeout(timer);
        }
        // 函数延迟执行
        timer = setTimeout(function () {
            fn.apply(_this, args);
        }, delay);
    };
}

export default { debounce};